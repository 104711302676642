<template>
  <div>
    <HeaderBasic>
      <h2 class="page-title">Manage Locality</h2>
      <router-link to="/" :exact="true">Home</router-link>
      <span> / </span>
      <span class="current">Manage Locality</span>
    </HeaderBasic>
    <Locality baseUrl="/Locality/"></Locality>
  </div>
</template>
<script>
export default {
  components: {
    HeaderBasic: () => import("./HeaderBasic"),
    Locality: () => import("./Locality")
  }
};
</script>
